import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { events } from "fetch-event-stream";
import { PUBLIC_SUPABASE_ANON_KEY } from "~/utils/constants";

export type Result = {
  provider_id: string;
  slug: string;
  name: string;
  instagram_followers: number | null;
  is_online: boolean;
  location_place_id: string;
  location_label: string;
  location_geography: string;
  images: string[];
  confidence?: "high" | "medium" | "low";
  differentiator?: string;
};

type Data =
  | {
      type: "results";
      data: Result[];
    }
  | {
      type: "evaluation";
      data: Result;
    };

type SearchContextType = {
  loading: boolean;
  search: (query: string, skip: number, location: UserLocation) => void;
  results: Result[];
  hasMore: boolean;
};

const SearchContext = createContext<SearchContextType>({
  loading: false,
  search: () => {},
  results: [],
  hasMore: true,
});

export function SearchProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(false);

  const abortController = useRef<AbortController | null>(null);

  const existing = useRef<string | null>(null);
  const [results, setResults] = useState<Result[]>([]);

  const search = useCallback(
    async (query: string, skip: number, location: UserLocation) => {
      try {
        if (
          existing.current === `${query}${location.lat}${location.lng}${skip}`
        ) {
          return;
        }

        if (query === "" || !location) {
          return;
        }

        existing.current = `${query}${location.lat}${location.lng}${skip}`;
        setLoading(true);

        if (abortController.current) {
          abortController.current.abort("New search started");
        }

        if (skip === 0) {
          setResults([]);
        }

        abortController.current = new AbortController();

        const response = await fetch(
          "https://gampynikfsrnysutgahc.supabase.co/functions/v1/search",
          {
            method: "POST",
            signal: abortController.current.signal,
            headers: {
              Authorization: `Bearer ${PUBLIC_SUPABASE_ANON_KEY}`,
            },
            body: JSON.stringify({
              query,
              skip,
              location: location,
            }),
          }
        );

        const stream = events(response, abortController.current.signal);

        for await (const event of stream) {
          if (!event.data) {
            continue;
          }

          const message = JSON.parse(event.data) as Data;

          if (message.type === "results") {
            setResults((prev) => [...prev, ...message.data]);
          }

          if (message.type === "evaluation") {
            setResults((prev) => {
              const newResults = [...prev];

              const index = newResults.findIndex(
                (r) => r.provider_id === message.data.provider_id
              );

              if (index !== -1) {
                newResults[index] = {
                  ...newResults[index],
                  ...message.data,
                };
              }

              return newResults;
            });
          }
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        if (
          error instanceof Error &&
          (error.name === "AbortError" || error.name === "TimeoutError")
        ) {
          console.log("\n\nAbortError: The run was aborted.");
        } else {
          toast.error("Unable to search at the moment. Please retry again.");
        }
      }
    },
    []
  );

  const hasMore = useMemo(() => {
    const last50 = results.slice(-50);

    const notHigh = last50.filter(
      (result) => result.confidence !== "high"
    ).length;

    if (notHigh === last50.length) {
      return false;
    }

    return true;
  }, [results]);

  return (
    <SearchContext.Provider
      value={{
        loading,
        search,
        results,
        hasMore,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export const useSearch = () => useContext(SearchContext);
